@charset "UTF-8";

/* ------------------------------------ PALETA DE CORES -----------------------------------------*/
:root {
    --amarelobrasao: #ffc107;
    --azulbrasao: #007bff;
    --azulpetroleo: #023d62;
    --verdebrasao: #02694c;
    --verdepetroleo: #003b44;
}

/* ----------------------------------------- TEXT ---------------------------------------------*/
.text-verdepetroleo {
    color: var(--verdepetroleo);
}
/* ------ FORM ---- */
label.error {
    font-weight: 400 !important;
    position: relative;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220,53,69,.9);
    border-radius: .25rem;
}

/* ---------------------------------------- TABELA ---------------------------------------------*/
table.dataTable thead {
    background-color: #f4f4f469;
    box-shadow: 0px 2px 5px grey;
}
/*Cabeçalho de tabela*/
table.dataTable thead > tr > th {
     padding-top: 0.5rem!important;
     padding-bottom: 0.5rem!important;
 }
/*Cabeçalho de tabela com scroll*/
div.dataTables_scrollBody > table > thead > tr > th {
    /*padding-top: 0.5rem!important;*/
    padding-bottom: 0rem!important;
}

div.dt-button-collection.dropdown-menu {
    padding: 0;
}

tr.selected {
    background: gray !important;
    color: white;
}

tr.selected > td > a.btn {
    color: black;
}tr.selected > td > a.btn:hover {
    color: gray;
}

 /*Customização das linhas caso seja verde ou vermelha em sequência*/
.odd.table-success > td {
    background-color: #afd9b9 !important;
}
.odd.table-success:hover > td {
    background-color: #a0d2ab !important;
}
.odd.table-danger > td {
    background-color: #ee9ea6 !important;
}
.odd.table-danger:hover > td {
    background-color: #e29ca3 !important;
}

/* ----------------------------------------- LOGIN ---------------------------------------------*/
.login-page, .register-page {
    /*background-color: #003b44;*/
    /*background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #003b44), to(#011d21));*/
    background-image: linear-gradient(180deg, #e9ecef 10%, #cfcfcf 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-box, .register-box {
    margin: 0 auto;
}

/* ---------------------------------------  MENU SUPERIOR --------------------------------------------*/
.img-circle {
    object-fit: cover;
}
.img-pm-perfil {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: #e5e5e5;
}

.dropdown-menu-center {
    left: -100px;
    right: auto;
}

.navbar-nav>.user-menu>.dropdown-menu>li.user-header {
    height: auto;
    background-image: url(../img/background_user_header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.navbar-nav>.user-menu>.dropdown-menu>li.user-header>p {
    background-color: #00000099;
    margin-top: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

/*.dropdown-menu-sm {*/
/*    max-width: 300px;*/
/*    min-width: 150px;*/
/*    padding: 0;*/
/*    margin: 0.6rem 0 0;*/
/*}*/

/*.dropdown-menu-sm .dropdown-divider {*/
/*    margin: 0;*/
/*}*/

/*.dropdown-menu-sm .dropdown-item {*/
/*    padding: 0.5rem 1rem;*/
/*}*/

/*.dropdown-menu-sm p {*/
/*    margin: 0;*/
/*    white-space: normal;*/
/*}*/

/*.dropdown-item:focus, .dropdown-item:hover {*/
/*    background-color: lightgray;*/
/*}*/


/* ----------------------------------------  MENU LATERAL -----------------------------------------*/
.sidebar-mini .nav-sidebar .nav-link,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link  {
    white-space: normal !important;
}

.sidebar-mini .nav-sidebar .nav-link p,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p {
    display: inline !important;
}

.sidebar-mini.sidebar-collapse .nav-sidebar .nav-link {
    white-space: nowrap !important;
}

.sidebar-mini.sidebar-collapse .sidebar .user-panel > .info,
.sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
.sidebar-mini.sidebar-collapse .brand-text {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden !important;
}
.sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .info,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-text,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .logo-xl, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info,
.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .brand-text,
.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .logo-xl {
    display: inline-block;
    margin-left: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible !important;
}



.sidebar {
    height: calc(100% - 6rem);
}
.brand-link .brand-image {
    max-height: 60px;
    /*margin-left: 0.0rem;*/
}

.nav-icon-img {
    height: 1.24rem;
}
.nav-icon-svg {
    filter: invert(100%) sepia(0%) saturate(10%) hue-rotate(249deg) brightness(100%) contrast(100%);
}

.svg-title {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(249deg) brightness(101%) contrast(100%);
}

ul > li > a > img.active {
    filter: invert(16%) sepia(69%) saturate(1449%) hue-rotate(156deg) brightness(88%) contrast(102%);
}

.bg-gradient-primary {
    background-color: #003b44;
    background-size: cover;
}

[class*='sidebar-dark-'] {
    background-color: #003b44;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #003b44), to(#011d21));
    background-image: linear-gradient(180deg, #003b44 10%, #011d21 100%);
}
.menu-open {
    background: #001315;
    transition: background-color 0.5s ease;
    border-radius: 5px;
}
.nav-sidebar .nav-item > .nav-link .right {
    transition: -webkit-transform ease-in-out 0.5s;
    transition: transform ease-in-out 0.5s;
    transition: transform ease-in-out 0.5s, -webkit-transform ease-in-out 0.5s;
}

.nav-sidebar .menu-open > .nav-link i.right {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

/************** Barra de rolagem ***************/
/*.sidebar::-webkit-scrollbar {*/
/*    width: 6px;*/
/*}*/

/*!* Track *!*/
/*.sidebar::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
/*    -webkit-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*}*/

/*!* Handle *!*/
/*.sidebar::-webkit-scrollbar-thumb {*/
/*    -webkit-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*    background: rgba(255, 255, 255, 0.8);*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
/*}*/
/*.sidebar::-webkit-scrollbar-thumb:window-inactive {*/
/*    background: rgba(255, 255, 255, 0.4);*/
/*}*/

/* ------------------------------------------  ÍCONES --------------------------------------------*/
.icon-img {
    height: 1.45rem;
}
img.icon-header {
    height: 2rem;
    filter: invert(16%) sepia(69%) saturate(1449%) hue-rotate(156deg) brightness(88%) contrast(102%);
}

img.nav-icon-modulo {
    filter: invert(100%) sepia(0%) saturate(10%) hue-rotate(249deg) brightness(100%) contrast(100%);
}

.nav-icon-modulo {
    height: 1.45rem;
}

/* ----------------------------------------  BOTÕES --------------------------------------------*/
.cursor-pointer {
    cursor: pointer
}
a.btn, a.nav-link, span.btn {
    cursor: pointer;
}

.btn-xl {
    font-size: 2rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.btn-secondary-pm {
    color: #ffffff;
    background-color: var(--azulpetroleo);
    border-color: var(--azulpetroleo);
    box-shadow: none;
}

.btn-secondary-pm:hover {
    color: #ffffff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-secondary-pm:focus, .btn-secondary-pm.focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}

.btn-secondary-pm.disabled, .btn-secondary-pm:disabled {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-secondary-pm:not(:disabled):not(.disabled):active, .btn-secondary-pm:not(:disabled):not(.disabled).active, .show > .btn-secondary-pm.dropdown-toggle {
    color: #ffffff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-secondary-pm:not(:disabled):not(.disabled):active:focus, .btn-secondary-pm:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-pm.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
/*Botão verde petróleo*/
.btn-primary-pm {
    color: #ffffff;
    background-color: var(--verdepetroleo);
    border-color: var(--verdepetroleo);
    box-shadow: none;
}

.btn-primary-pm:hover {
    color: #ffffff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary-pm:focus, .btn-primary-pm.focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}

.btn-primary-pm.disabled, .btn-primary-pm:disabled {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary-pm:not(:disabled):not(.disabled):active, .btn-primary-pm:not(:disabled):not(.disabled).active, .show > .btn-primary-pm.dropdown-toggle {
    color: #ffffff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary-pm:not(:disabled):not(.disabled):active:focus, .btn-primary-pm:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-pm.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
/* botão fechar */
.btn-icon-split {
    padding: 0;
    overflow: hidden;
    display: inline-flex;
    align-items: stretch;
    justify-content: center;
}

.btn-icon-split .icon {
    background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: 0.375rem 0.75rem;
}

.btn-icon-split .text {
    display: inline-block;
    padding: 0.375rem 0.75rem;
}

.btn-icon-split.btn-sm .icon, .btn-group-sm > .btn-icon-split.btn .icon {
    padding: 0.25rem 0.5rem;
}

.btn-icon-split.btn-sm .text, .btn-group-sm > .btn-icon-split.btn .text {
    padding: 0.25rem 0.5rem;
}

.btn-icon-split.btn-lg .icon, .btn-group-lg > .btn-icon-split.btn .icon {
    padding: 0.5rem 1rem;
}

.btn-icon-split.btn-lg .text, .btn-group-lg > .btn-icon-split.btn .text {
    padding: 0.5rem 1rem;
}
/* botão de upload */
.custom-file-label::after {
/*     content: "Anexo"; */
    font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f002";
}

.custom-file-pdf-label::after {
    content: "PDF";
}

/* ----------------------------------------  CARD --------------------------------------------*/
.card {
    width: 100%;
}


/* ----------------------------------------  BORDAS DIVs--------------------------------------------*/
.border-left-primary {
    border-left: 0.25rem solid #4e73df !important;
}

.border-bottom-primary {
    border-bottom: 0.25rem solid #4e73df !important;
}

.border-left-secondary {
    border-left: 0.25rem solid #858796 !important;
}

.border-bottom-secondary {
    border-bottom: 0.25rem solid #858796 !important;
}

.border-left-success {
    border-left: 0.25rem solid #1cc88a !important;
}

.border-bottom-success {
    border-bottom: 0.25rem solid #1cc88a !important;
}

.border-left-info {
    border-left: 0.25rem solid #36b9cc !important;
}

.border-bottom-info {
    border-bottom: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
    border-left: 0.25rem solid #f6c23e !important;
}

.border-bottom-warning {
    border-bottom: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
    border-left: 0.25rem solid #e74a3b !important;
}

.border-bottom-danger {
    border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-light {
    border-left: 0.25rem solid #f8f9fc !important;
}

.border-bottom-light {
    border-bottom: 0.25rem solid #f8f9fc !important;
}

.border-left-dark {
    border-left: 0.25rem solid #5a5c69 !important;
}

.border-bottom-dark {
    border-bottom: 0.25rem solid #5a5c69 !important;
}

.border-bottom-verdepetroleo {
    border-bottom: 0.25rem solid #1a4d54 !important;
}

/* ----------------------------------------  COMPLEMENTOS --------------------------------------------*/
/* scroll topo */
.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;
    border: none;
}

/*Quebra de página*/
@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}
/*Rolagem da tela em modal Bootbox*/
.bootbox.modal{
    overflow: auto !important;
}
/* ----------------- Barra de rolagem */
/*::-webkit-scrollbar {*/
/*    width: 8px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
/*    -webkit-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    -webkit-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*    background: rgb(0, 59, 68);*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
/*}*/
/*::-webkit-scrollbar-thumb:window-inactive {*/
/*    background: rgba(0, 59, 68, 0.84);*/
/*}*/

/* ----------------------------------------  LOADER --------------------------------------------*/
.loader-modal > .modal-dialog >.modal-content {
    background: none;
    box-shadow: none;
    border: none;
}
#loader-wrapper {
    position: relative;
    top: 0;
    left: 0;
    margin-top: -147px;
    margin-left: -147px;
    z-index: 1000;
}

#loader-logo {
    display: block;
    position: relative;
    height: 294px;
    width: 294px;
    background: url(../img/aguarde/brasao_nti_loader.png) no-repeat center;
    background-size: contain;
    z-index: 1001;
}

/*#loader-placa {*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 7%;*/
/*    top: 7%;*/
/*    height: 252px;*/
/*    width: 252px;*/
/*    background: url(../img/brasao_nti_loader_placa.png) no-repeat center;*/
/*    background-size: contain;*/
/*    animation: placa-loader 5s linear infinite;*/
/*    !* Chrome, Firefox 16+, IE 10+, Opera *!*/
/*    z-index: 1002;*/
/*}*/

/*#loader-sigla {*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 30%;*/
/*    top: 8%;*/
/*    height: 130px;*/
/*    width: 130px;*/
/*    background: url(../img/brasao_nti_loader_sigla.png) no-repeat center;*/
/*    background-size: contain;*/
/*    z-index: 1003;*/
/*}*/

/*#loader-engrenagem-fundo {*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 35%;*/
/*    top: 49%;*/
/*    height: 85px;*/
/*    width: 85px;*/
/*    background: url(../img/brasao_nti_loader_engrenagem_fundo.png) no-repeat center;*/
/*    background-size: contain;*/
/*    -webkit-animation: spin 2s linear infinite;*/
/*    !* Chrome, Opera 15+, Safari 5+ *!*/
/*    animation: spin 3s linear infinite;*/
/*    !* Chrome, Firefox 16+, IE 10+, Opera *!*/
/*    z-index: 1004;*/
/*}*/

/*#loader-devredes {*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 12%;*/
/*    top: 53%;*/
/*    height: 64px;*/
/*    width: 225px;*/
/*    background: url(../img/brasao_nti_loader_devredes.png) no-repeat center;*/
/*    background-size: contain;*/
/*    animation: devredes-loader 3s linear infinite;*/
/*    z-index: 1005;*/
/*}*/

#loader-engrenagem {
    display: block;
    position: absolute;
    left: 31%;
    top: 44%;
    height: 113px;
    width: 113px;
    background: url(../img/aguarde/brasao_nti_loader_engrenagem.png) no-repeat center;
    background-size: contain;
    -webkit-animation: spin 2s ease-in-out infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s ease-in-out infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1006;
}

#loader-garrucha {
    display: block;
    position: absolute;
    left: 39.6%;
    top: 53%;
    height: 60px;
    width: 60px;
    background: url(../img/aguarde/brasao_nti_loader_garrucha.png) no-repeat center;
    background-size: contain;
    z-index: 1007;
}

#loader {
    display: block;
    position: relative;
    left: -3px;
    top: -3px;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: var(--azulpetroleo);
    -webkit-animation: spin 2s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1001;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 0px solid transparent;
    border-top-color: var(--azulpetroleo);
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin-n 800ms linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: var(--azulpetroleo);
    -webkit-animation: spin 3.0s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin-n 1s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader-text {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    width: 294px;
    animation: fade-in-out 3s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin-n {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(-360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(-360deg);
        /* IE 9 */
        transform: rotate(-360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes zoom-in-out {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.03,1.03);
    }
    100% {
        transform: scale(1,1);
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 0.6;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}

/*.single-featured-image-header img {*/
/*    animation: zoominoutsinglefeatured 15s forwards;*/
/*}*/

/*!* JavaScript Turned Off *!*/

/*.no-js #loader-wrapper {*/
/*    display: none;*/
/*}*/

/*.progress {*/
/*    background-color: rgba(255, 64, 129, 0.22);*/
/*}*/


/* ------------------------------------- EFEITOS -----------------------------------------------*/
.shadow-obj {
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
}
/* ------------------------------------- ANIMAÇÕES -----------------------------------------------*/
@-webkit-keyframes growIn {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes growIn {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.animated--grow-in {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    /*-webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);*/
    /*animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);*/
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated--fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    /*-webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);*/
    /*animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);*/
}

/* ------------------------------------- PÁGINAS ESPECÍFICAS -----------------------------------------------*/
/*Princiapl > Minha Ficha*/
.profile-user-img {
    height: 250px;
    width: 250px;
}


/* ------------------------------------------------------------------------------------------------*/

/*.input-group-append {*/
/*	border: 1px solid #ced4da;*/
/*    border-radius: 4px;*/
/*	display: flow-root !important;*/
/*}*/

/*.input-group-append > button {*/
/*	border: none;*/
/*}*/

.tox-notifications-container {
    display: none !important;
}

.btnFinalizar {
    width: 100%;
    max-width: 100%;
    margin: 0;
    text-align: center;
}

#imagemPaciente {
    appearance: none;
    width: 30%;
    height: 240px;
    border: 1px solid black;
    border-radius: 10px;
    display: block;
}

#nomePaciente, #matriculaPaciente, #cpfPaciente, #instituicaoPaciente {
    display: inline-block;
}

#dadosPaciente {
    margin: auto;
    display: inline-block;
}


/* CID SOBREPOR MODAIS */
.ui-front {
    z-index: 9999 !important;
}